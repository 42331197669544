<template>
  <div class="element-div">
    <div class="flex card-content-item attr-box">
      <div class="flex prop-box">
        <el-tooltip class="item" effect="dark" content="顶点" placement="top">
          <div class="text_hidden propname">
            顶点
          </div>
        </el-tooltip>
        <div class="flex propvalue">
          <el-select
            v-model="elementData.value"
            placeholder="请选择"
            @change="changePoints"
          >
            <el-option
              v-for="(item, idx) in elementData.list"
              :key="idx"
              :label="'顶点' + (idx + 1)"
              :value="idx"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="flex card-content-item attr-box">
      <div class="flex prop-box">
        <div class="text_hidden propname">x轴</div>
        <div class="flex propvalue">
          <div
            class="input-item-box"
            @dblclick="onShowPoint('showPx', 'inputX')"
            :class="{ p15: !showPx }"
          >
            <el-input
              v-if="showPx"
              v-model="point.x"
              placeholder="请输入x轴"
              ref="inputX"
              @keyup.enter.native="editAttr(point, 'showPx')"
              @blur="() => editAttr(point, 'showPx')"
              @input="point.x = checkGreaterZero(point.x, 'number')"
            ></el-input>
            <template v-else>
              {{ point.x }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="flex card-content-item attr-box">
      <div class="flex prop-box">
        <div class="text_hidden propname">y轴</div>
        <div class="flex propvalue">
          <div
            class="input-item-box"
            @dblclick="onShowPoint('showPy', 'inputY')"
            :class="{ p15: !showPy }"
          >
            <el-input
              v-if="showPy"
              v-model="point.y"
              placeholder="请输入x轴"
              ref="inputY"
              @keyup.enter.native="editAttr(point, 'showPy')"
              @blur="() => editAttr(point, 'showPy')"
              @input="point.y = checkGreaterZero(point.y, 'number')"
            ></el-input>
            <template v-else>
              {{ point.y }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="flex card-content-item attr-box">
      <div class="flex prop-box">
        <div class="text_hidden propname">长度</div>
        <div class="flex propvalue">
          <div class="input-item-box p15">
            <template>
              {{ lineLength }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dyxLine",
  props: {
    pixiMap: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    featureDatas: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    fn: {
      type: Function
    }
  },
  components: {},
  data() {
    return {
      elementData: this.resetData(),
      showPx: false,
      showPy: false,
      lineLength: "",
      point: {
        x: "",
        y: "",
        index: 0
      }
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    // 数据赋值
    initData(datas) {
      if (datas.length == 1) {
        let geometry = datas[0];
        this.elementData.list = geometry._entity_data._vertices.map(item => {
          return {
            x: Number(item.x).toFixed(3),
            y: Number(item.y).toFixed(3),
            length: geometry._entity_data.getLength()
              ? Number(geometry._entity_data.getLength()).toFixed(2)
              : ""
          };
        });
      } else {
        datas.map(item => {
          let list = item._entity_data._vertices.map(it => {
            return {
              x: Number(it.x).toFixed(3),
              y: Number(it.y).toFixed(3),
              length: item._entity_data.getLength()
                ? Number(item._entity_data.getLength()).toFixed(2)
                : ""
            };
          });
          this.elementData.list.push(...list);
        });
      }
      let x = "请输入x轴",
        y = "请输入y轴",
        index = 0,
        length = "";
      if (this.elementData.list.length > 0) {
        x = this.elementData.list[0].x
          ? Number(this.elementData.list[0].x).toFixed(3)
          : 0;
        y = this.elementData.list[0].y
          ? Number(this.elementData.list[0].y).toFixed(3)
          : 0;
        length = this.elementData.list[0].length;
      }
      this.lineLength = length;
      this.$set(this.point, "x", x);
      this.$set(this.point, "y", y);
      this.$set(this.point, "index", index);
    },
    // 重置数据
    resetData() {
      return {
        name: "多义线",
        sname: "LTPolyline",
        type: "moreInput",
        show: false,
        value: 0,
        namesL: "顶点",
        list: [],
        length: ""
      };
    },
    // 线-双击显示表单
    onShowPoint(type = "", inputName = "") {
      if (!this[type]) {
        this[type] = true;
        this.$nextTick(() => {
          this.$refs[inputName].focus();
        });
      } else this[type] = false;
    },
    //
    editAttr(point, type) {
      this.fn(point, type);
    },
    // 规定typeName为number表单只能输入数字、小数点和负号
    checkGreaterZero(value, type) {
      if (type == "number") value = value.replace(/[^\-?\d.]/g, "");
      return value;
    },
    changePoints(val) {
      let point = this.elementData.list[val];
      this.$set(this.point, "x", point.x);
      this.$set(this.point, "y", point.y);
      this.$set(this.point, "index", val);
      this.lineLength = point.length;
    }
  },
  watch: {
    featureDatas: {
      handler(newVal, oldVal) {
        this.elementData = this.resetData();
        this.point = {
          x: "",
          y: "",
          index: 0
        };
        if (newVal && newVal.length > 0 && oldVal != newVal) {
          this.initData(newVal);
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'

.element-div
  position relative
  width 100%
  height 100%
</style>
